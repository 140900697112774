import { Box, CssBaseline, CssVarsProvider } from "@mui/joy";
import { Navigate, Route, Routes } from "react-router-dom";
import SimpleFromAppStartPage from "./start/SimpleFormAppStartPage";
import SimpleFromAppFinishPage from "./finish/SimpleFormAppFinishPage";
import SimpleFormAppProcessPage from "./process/SimpleFormAppProcessPage";
import SimpleFormAppLastPage from "./last/SimpleFormAppLastPage";
import SimpleFromAppPersonalInfoPage from "./personal-info/SimpleFormAppStartInfoPage";
import SimpleFormSelectPage from "./select/SimpleFormSelectPage";

export default function SimpleFormAppPage() {
  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box>
        <Routes>
          <Route path='/start' element={<SimpleFromAppStartPage />} />
          <Route path='/personal-info' element={<SimpleFromAppPersonalInfoPage />} />
          <Route path='/select' element={<SimpleFormSelectPage />} />
          <Route path='/process' element={<SimpleFormAppProcessPage />} />
          <Route path='/last' element={<SimpleFormAppLastPage />} />
          <Route path='/finish' element={<SimpleFromAppFinishPage />} />
        </Routes>
      </Box>

    </CssVarsProvider>
  )
}