import { Box, Stack, Typography } from "@mui/joy";

export default function SimpleFromAppFinishPage() {
  return <>
    <Stack
      marginTop={10}
      alignItems='center'
    > 
      <Typography fontSize={25} fontWeight={600}>
        감사합니다.
      </Typography>
    </Stack>
  </>
}