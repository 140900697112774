import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Select, Stack, Option, Checkbox, Divider, Link, Typography } from "@mui/joy";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiClient } from "../../../lib/api/api-client";

type FormDataType = {
  userName?: string
  userPhone?: string
  petName?: string
  petType?: "DOG" | "CAT"
  petAge?: number
  termAgeAgreed: boolean
  termServiceAgreed: boolean
  termReceiveAdAgreed: boolean
}

type FormInfoApiResponse = {
  hospitalName?: string,
  hospitalLogoImageUrl?: string
}

export default function FromAppPersonalInfoPage() {
  const navigate = useNavigate()
  const [formInfoApiResponse, setFormInfoApiResponse] = useState<FormInfoApiResponse | undefined>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [formData, setFormData] = useState<FormDataType>({
    termAgeAgreed: false,
    termServiceAgreed: false,
    termReceiveAdAgreed: false
  })
  const client = apiClient()

  const answerUid = searchParams.get('answerUid')!

  useEffect(() => {
    client.GET(
      '/app/simple-form/answer/info',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      setFormInfoApiResponse(res.data as FormInfoApiResponse)
    })
  }, [])
  
  const startAnswer = (event: FormEvent<any>) => {
    event.preventDefault()
    console.log(formData)
    client.PUT(
      '/app/simple-form/personal-info',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        },
        body: {
          userName: formData?.userName!,
          userPhone: formData?.userPhone!,
          petName: formData?.petName!,
          petType: formData?.petType!,
          petAge: formData?.petAge!,
          termAgeAgreed: formData?.termAgeAgreed,
          termServiceAgreed: formData?.termServiceAgreed,
          termReceiveAdAgreed: formData?.termReceiveAdAgreed
        }
      }
    ).then(res => {
      if (!res.error) {
        navigate(`../select?answerUid=${answerUid}`, { replace: true })
      }
    })
    
  }

  return <>
  <Stack
    alignItems='center'
  >

    <Box 
      sx={{
        position: 'fixed',
        margin: 'auto',
        backgroundColor: 'white',
        borderBottom: '1px solid #D8D8D8',
        height: 45 + 1,
        top: 0,
        left: 0,
        right: 0
      }}
    >
      <Stack
        direction='row'
        alignItems='centor'
        justifyContent='flex-start'
        spacing={0}
        pl={2}
      >
        {
          formInfoApiResponse?.hospitalLogoImageUrl &&
            <Box component='img' src={formInfoApiResponse?.hospitalLogoImageUrl!} sx={{height: '45px'}} />
          || formInfoApiResponse?.hospitalName &&
            <Typography fontSize='20px' lineHeight='45px' fontWeight={600}>{formInfoApiResponse?.hospitalName}</Typography>
        }
        <Box width={10} />
        <Typography fontSize='18px' lineHeight='45px'>사전문진</Typography>
      </Stack>
    </Box>
    
    <Box sx={{height: 46}} />



    <Typography 
      fontWeight={600}
      marginTop={4}
      marginBottom={4}
    >
      반려동물과 보호자님의 정보를 입력해 주세요.
    </Typography>

    <Box
      sx={{
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3
      }}
    >
      <form onSubmit={startAnswer}>
        <Stack
          spacing={2}
        >
          <Input placeholder="보호자명" required onChange={ev => setFormData({...formData, userName: ev.target.value})} />

          <Input placeholder="휴대전화 번호" required onChange={ev => {
            const value = ev.target.value.replace(/[^0-9]/g, '')
            setFormData({...formData, userPhone: value})
            ev.target.value = value
          }} />

          <Input placeholder="반려동물명" required onChange={ev => setFormData({...formData, petName: ev.target.value})} />

          <Select
            placeholder="반려동물을 선택해 주세요."
            required
            sx={{ width: '100%' }}
            onChange={(ev, value) => setFormData({...formData, petType: value as "DOG" | "CAT"})}
          >
            <Option key="DOG" value="DOG">🐶 강아지</Option>
            <Option key="CAT" value="CAT">🐱 ‍고양이</Option>
          </Select>
          

          <Select
            placeholder="반려동물의 나이를 선택해 주세요."
            required
            sx={{ width: '100%' }}
            onChange={(ev, value) => setFormData({...formData, petAge: value as number})}
          >
            <Option key={0} value={0} >1살 미만</Option>
            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
              11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
              21, 22, 23, 24, 25, 26, 27, 28, 29, 30
            ].map(age => {
              return <Option key={age} value={age} >{age}살</Option>
            })}
          </Select>

          <Box height={2} />

          <Checkbox
            label="모두 동의하기"
            checked={formData.termAgeAgreed && 
              formData.termServiceAgreed &&
              formData.termReceiveAdAgreed}
            onChange={(ev) => {
              setFormData({
                ...formData,
                termAgeAgreed: ev.target.checked,
                termServiceAgreed: ev.target.checked,
                termReceiveAdAgreed: ev.target.checked
              })
            }}
          />
          <Divider />

          <Checkbox
            label="(필수) 만 14세 이상입니다."
            onChange={(ev) => setFormData({...formData, termAgeAgreed: ev.target.checked})}
            checked={formData.termAgeAgreed}
            required 
          />

          <Checkbox
            label={
              <div>
                <span>(필수) 서비스 이용약관 에 동의합니다. </span>
                <Link href='https://sites.google.com/view/hago-terms' target="_blank" rel="noopener" sx={{zIndex: 2}}>보기</Link>
              </div>
            } 
            onChange={(ev) => setFormData({...formData, termServiceAgreed: ev.target.checked})}
            checked={formData.termServiceAgreed}
            required />

          <Checkbox
            label="(선택) 광고성 정보 수신 동의" 
            onChange={(ev) => setFormData({...formData, termReceiveAdAgreed: ev.target.checked})}
            checked={formData.termReceiveAdAgreed}
          />


          <Box height={100} />
        </Stack>



        <Box
          sx={{
            position: 'fixed',
            margin: 'auto',
            left: 20,
            right: 20,
            bottom: 20
          }}
        >

          <Button
            type="submit"
            sx={{
              width: '100%'
            }}
            size="lg"
            // onClick={() => startAnswer()}
          >시작하기</Button>
        </Box>
      </form>
    </Box>

  </Stack>

  </>
}