import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SimpleFormAppPage from './pages/simple-form/SimpleFormAppPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/form'/>}/>
      <Route path='/simple-form/*' element={<SimpleFormAppPage/>}/>
    </Routes>
  );
}

export default App;
