import { Box, Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiClient } from "../../../lib/api/api-client";

type ApiResponse = {
  hospitalName?: string,
  hospitalLogoImageUrl?: string
}

export default function SimpleFromAppStartPage() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [formInfoApiResponse, setFormInfoApiResponse] = useState<ApiResponse | undefined>()
  const client = apiClient()

  const partnerId = parseInt(searchParams.get('partnerId')!)

  useEffect(() => {
    client.GET(
      '/app/simple-form/info',
      {
        params: {
          query: {
            partnerId: partnerId
          }
        }
      }
    ).then((res) => {
      setFormInfoApiResponse(res.data as ApiResponse)
    })
  }, [])
  
  const startAnswer = () => {
    client.POST(
      '/app/simple-form/start',
      {
        params: {
          query: {
            partnerId: partnerId
          }
        }
      }
    ).then(res => {
      navigate(`../personal-info?answerUid=${res.data!.answerUid}`)
    })
  }

  return <>
  <Stack
    alignItems='center'
  >
    <Box 
      sx={{
        position: 'fixed',
        margin: 'auto',
        backgroundColor: 'white',
        borderBottom: '1px solid #D8D8D8',
        height: 45 + 1,
        top: 0,
        left: 0,
        right: 0
      }}
    >
      <Stack
        direction='row'
        alignItems='centor'
        justifyContent='flex-start'
        spacing={0}
        pl={2}
      >
        {
          formInfoApiResponse?.hospitalLogoImageUrl &&
            <Box component='img' src={formInfoApiResponse?.hospitalLogoImageUrl!} sx={{height: '45px'}} />
          || formInfoApiResponse?.hospitalName &&
            <Typography fontSize='20px' lineHeight='45px' fontWeight={600}>{formInfoApiResponse?.hospitalName}</Typography>
        }
        <Box width={10} />
        <Typography fontSize='18px' lineHeight='45px'>사전문진</Typography>
      </Stack>
    </Box>
    
    <Box sx={{height: 46}} />




    <Box sx={{height: 60}} />

    <Box 
      component='img'
      width='80%'
      maxWidth={400}
      mb={5}
      src='https://cdn.hago.dog/files/6a6f32d7-4b5b-474f-a3f5-3173da90acac.png'
     />

    <Typography>
      소중한 아이의 좀 더 정확한 진료를 위한 과정입니다.
    </Typography>

    <Typography mt='10px'>
      번거로우시더라도 꼼꼼히 체크 부탁드립니다
    </Typography>


    <Box height={100} />
  </Stack>

  <Box
      sx={{
        position: 'fixed',
        margin: 'auto',
        left: 20,
        right: 20,
        bottom: 20
      }}
    >
    <Button
      sx={{
        width: '100%'
      }}
      size="lg"
      onClick={() => startAnswer()}
    >시작하기</Button>
  </Box>
  </>
}