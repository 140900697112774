import { useEffect, useState } from "react"
import { apiClient } from "../../../lib/api/api-client"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Box, Button, Grid, Stack, Typography } from "@mui/joy"

type FormInfoApiResponse = {
  hospitalName?: string,
  hospitalLogoImageUrl?: string
}

export default function SimpleFormAppLastPage() {
  const [loading, setLoading] = useState(false)
  const [formInfoApiResponse, setFormInfoApiResponse] = useState<FormInfoApiResponse | undefined>()
  const client = apiClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const answerUid = searchParams.get('answerUid')!

  useEffect(() => {
    client.GET(
      '/app/simple-form/answer/info',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      setFormInfoApiResponse(res.data as FormInfoApiResponse)
    })
  }, [])

  const goBack = () => {
    setLoading(true)
    client.POST(
      '/app/simple-form/back',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then(res => {
      navigate(`../process?answerUid=${answerUid}`)
    })
  }

  const goFinish = () => {
    setLoading(true)
    client.POST(
      '/app/simple-form/finish',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then(res => {
      navigate(`../finish?answerUid=${answerUid}`)
    })
  }

  return <>
  <Box 
    sx={{
      position: 'fixed',
      margin: 'auto',
      backgroundColor: 'white',
      borderBottom: '1px solid #D8D8D8',
      height: 45 + 1,
      top: 0,
      left: 0,
      right: 0
    }}
  >
    <Stack
      direction='row'
      alignItems='centor'
      justifyContent='flex-start'
      spacing={0}
      pl={2}
    >
      {
        formInfoApiResponse?.hospitalLogoImageUrl &&
          <Box component='img' src={formInfoApiResponse?.hospitalLogoImageUrl!} sx={{height: '45px'}} />
        || formInfoApiResponse?.hospitalName &&
          <Typography fontSize='20px' lineHeight='45px' fontWeight={600}>{formInfoApiResponse?.hospitalName}</Typography>
      }
      <Box width={10} />
      <Typography fontSize='18px' lineHeight='45px'>사전문진</Typography>
    </Stack>
  </Box>

  <Box sx={{height: 46}} />


  <Stack
    marginTop={10}
    alignItems='center'
  >
    
    <Typography fontSize={100} mb={5}>
      ✔️
    </Typography>

    <Typography fontSize={25} fontWeight={600}>
      문진 작성이 완료되었습니다.
    </Typography>

    <Typography mt={3}>
      아래 제출하기 버튼을 눌러 제출해 주세요.
    </Typography>

    <Typography mt={1}>
      * 제출 후에는 수정이 불가능합니다.
    </Typography>
  </Stack>

  <Box
    sx={{
      position: 'fixed',
      margin: 'auto',
      left: 20,
      right: 20,
      bottom: 20
    }}
  >
    <Grid
      container
      spacing={1}
    >
      <Grid xs={4}>
        <Button
          fullWidth
          size="lg"
          variant="outlined"
          onClick={() => goBack()}
          disabled={loading}
        >
          이전
        </Button>
      </Grid>


      <Grid xs={8}>
        <Button
          fullWidth
          size="lg"
          onClick={() => goFinish()}
          disabled={loading}
        >
          제출하기
        </Button>
      </Grid>
    </Grid>
  </Box>
  </>
}