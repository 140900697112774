import { Box, Button, Checkbox, Input, Sheet, Stack, Typography } from "@mui/joy"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { apiClient } from "../../../lib/api/api-client"


type FormInfoApiResponse = {
  hospitalName?: string,
  hospitalLogoImageUrl?: string
}

type FormSelectList = {
  items: Array<{
    id: number
    name: string
  }>
}

export default function SimpleFormSelectPage() {
  
  const navigate = useNavigate()
  const [formInfoApiResponse, setFormInfoApiResponse] = useState<FormInfoApiResponse | undefined>()
  const [formSelectList, setFormSelectList] = useState<FormSelectList>({items: []})
  const [selectedId, setSelectedId] = useState<number>()
  const [searchParams, setSearchParams] = useSearchParams()
  const client = apiClient()

  const answerUid = searchParams.get('answerUid')!

  useEffect(() => {
    client.GET(
      '/app/simple-form/answer/info',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      setFormInfoApiResponse(res.data as FormInfoApiResponse)
    })

    
    client.GET(
      '/app/simple-form/select/list',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      setFormSelectList(res.data as FormSelectList)
    })

  }, [])
  


  return <>
  <Stack
    alignItems='center'
  >

    <Box 
      sx={{
        position: 'fixed',
        margin: 'auto',
        backgroundColor: 'white',
        borderBottom: '1px solid #D8D8D8',
        height: 45 + 1,
        top: 0,
        left: 0,
        right: 0
      }}
      zIndex={10}
    >
      <Stack
        direction='row'
        alignItems='centor'
        justifyContent='flex-start'
        spacing={0}
        pl={2}
      >
        {
          formInfoApiResponse?.hospitalLogoImageUrl &&
            <Box component='img' src={formInfoApiResponse?.hospitalLogoImageUrl!} sx={{height: '45px'}} />
          || formInfoApiResponse?.hospitalName &&
            <Typography fontSize='20px' lineHeight='45px' fontWeight={600}>{formInfoApiResponse?.hospitalName}</Typography>
        }
        <Box width={10} />
        <Typography fontSize='18px' lineHeight='45px'>사전문진</Typography>
      </Stack>
    </Box>
    
    <Box sx={{height: 46}} />



    <Typography 
      fontWeight={600}
      marginTop={4}
      marginBottom={4}
    >
      주증상이 무엇인가요?
    </Typography>

    <Box
      sx={{
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3
      }}
    >
      <form onSubmit={() => {}}>
        <Stack
          spacing={2}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {formSelectList.items.map(item => {
              return <Sheet
                variant="outlined"
                key={item.id}
              >
                <Checkbox
                  sx={{ p: 2, borderRadius: 'md', display: 'flex' }}
                  overlay
                  checked={selectedId == item.id}
                  value={item.id}
                  label={item.name}
                  onChange={(item) => {setSelectedId(parseInt(item.target.value))}}
                />
              </Sheet>
            })}
            
          </Box>

          <Box height={100} />
        </Stack>


        <Box 
          sx={{
            backgroundColor: 'white',
            position: 'fixed',
            margin: 'auto',
            left: 0,
            right: 0,
            bottom: 0
          }}
          height={80}
          zIndex={10}
        />
        <Box
          sx={{
            position: 'fixed',
            margin: 'auto',
            left: 20,
            right: 20,
            bottom: 20
          }}
          zIndex={10}
        >

          <Button
            type="submit"
            sx={{
              width: '100%'
            }}
            size="lg"
            onClick={async (event) => {
              event.preventDefault()
              if (!selectedId) {
                alert('주증상을 선택해 주세요.')
                return
              }

              const resp = await client.POST(
                '/app/simple-form/select',
                {
                  params: {
                    query: {
                      answerUid: answerUid
                    }
                  },
                  body: {
                    questionSetId: selectedId
                  }
                }
              )

              if (resp.error) {
                alert('오류 발생')
                return
              }
              
              navigate(`../process?answerUid=${answerUid}`, { replace: true })

            }}
          >시작하기</Button>
        </Box>
      </form>
    </Box>

  </Stack>

  </>
}