import { Box, Button, Checkbox, Grid, Input, LinearProgress, Sheet, Stack, Typography, useTheme } from "@mui/joy"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { apiClient } from "../../../lib/api/api-client"

type ApiResponse = {
  questionItem: {
    id: number
    question: string
    itemGroupName: string
    answerType: string
    selectAnswers: Array<string>
    selectAllowEtc: boolean
    selectAllowMultiple: boolean
  }
  answerItem: {
    id: number
    answerSelect?: Array<string>
    answerText?: string
  }
  isFirst: boolean
  progress: number
  questionCountTotal: number
  questionCountCurrent: number
}

type AnswerType = {
  answerSelect: Set<string>
  answerText: string
}

type FormInfoApiResponse = {
  hospitalName?: string
  hospitalLogoImageUrl?: string
  questionSetName?: string
}

export default function SimpleFromAppProcessPage() {
  const theme = useTheme()
  const [apiResponse, setApiResponse] = useState<ApiResponse | undefined>()
  const [answer, setAnswer] = useState<AnswerType>({
    answerSelect: new Set(),
    answerText: ""
  })
  const [etc, setEtc] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const client = apiClient()
  const [formInfoApiResponse, setFormInfoApiResponse] = useState<FormInfoApiResponse | undefined>()


  const answerUid = searchParams.get('answerUid')!

  const putAnswer = () => {
    setLoading(true)
    if (etc) {
      answer.answerSelect.add(etc)
      setAnswer({...answer})
    }
    client.PUT(
      '/app/simple-form/process',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        },
        body: {
          answerSelect: answer.answerSelect ? Array.from(answer.answerSelect) : undefined,
          answerText: answer.answerText,
          questionItemId: apiResponse?.questionItem.id!
        }
      }
    ).then(res => {
      refreshQuestion()
    })
  }

  const refreshQuestion = () => {
    setAnswer({
      answerSelect: new Set(),
      answerText: ""
    })
    setEtc(undefined)
    client.GET(
      '/app/simple-form/process',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      if (res.data?.isFinished) {
        alert('이미 제출한 문진입니다.')
        navigate(`../finish?answerUid=${answerUid}`)
      } else if (res.data?.isLast) {
        navigate(`../last?answerUid=${answerUid}`)
      }
      setAnswer({
        answerSelect: res.data?.answerItem?.answerSelect ? new Set(res.data?.answerItem?.answerSelect.filter((v) => {return res.data.questionItem?.selectAnswers?.indexOf(v) !== -1})) : new Set(),
        answerText: res.data?.answerItem?.answerText ? res.data?.answerItem?.answerText : ""
      })
      setEtc(res.data?.answerItem?.answerSelect?.filter((v) => {return res.data.questionItem?.selectAnswers?.indexOf(v) == -1})[0])
      setApiResponse(res.data as ApiResponse)
      setLoading(false)
    })
  }

  const goBack = () => {
    setLoading(true)
    client.POST(
      '/app/simple-form/back',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then(res => {
      refreshQuestion()
    })
  }

  useEffect(() => {
    refreshQuestion()
    
    client.GET(
      '/app/simple-form/answer/info',
      {
        params: {
          query: {
            answerUid: answerUid
          }
        }
      }
    ).then((res) => {
      setFormInfoApiResponse(res.data as FormInfoApiResponse)
    })
  }, [])

  return <>
  <Stack 
  >
    <Box 
      sx={{
        position: 'fixed',
        margin: 'auto',
        backgroundColor: 'white',
        borderBottom: '1px solid #D8D8D8',
        height: 45 + 1,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10
      }}
    >
      <Stack
        direction='row'
        alignItems='centor'
        justifyContent='flex-start'
        spacing={0}
        pl={2}
      >
        {
          formInfoApiResponse?.hospitalLogoImageUrl &&
            <Box component='img' src={formInfoApiResponse?.hospitalLogoImageUrl!} sx={{height: '45px'}} />
          || formInfoApiResponse?.hospitalName &&
            <Typography fontSize='20px' lineHeight='45px' fontWeight={600}>{formInfoApiResponse?.hospitalName}</Typography>
        }
        <Box width={10} />
        <Typography fontSize='18px' lineHeight='45px'>사전문진</Typography>
        {formInfoApiResponse?.questionSetName &&
          <Typography fontSize='18px' lineHeight='45px' ml={1}>- {formInfoApiResponse?.questionSetName}</Typography>
        }
      </Stack>
    </Box>
    
    <Box sx={{height: 46}} />

    <Typography textAlign={"center"} fontWeight={600} fontSize={15} mt={3}>
      {apiResponse?.questionItem.itemGroupName}
    </Typography>

    <Typography textAlign={"center"} mt={0}>
      <Typography textColor={theme.palette.primary[400]} fontWeight={800} fontSize={25}>{apiResponse?.questionCountCurrent}</Typography>
      <Typography fontWeight={600} fontSize={20}> / {apiResponse?.questionCountTotal}</Typography>
    </Typography>


    <Box
      mt={1}
      ml={10}
      mr={10}
    >
      <LinearProgress
        color="primary"
        determinate
        size="sm"
        value={apiResponse?.progress}
        variant="soft"
        sx={{
          zIndex: -1
        }}
      />
    </Box>
    
    

    <Typography 
      textAlign={"center"} 
      fontWeight={600} 
      fontSize={20} 
      mt={4}
      pl={2}
      pr={2}
      sx={{
        wordBreak: 'keep-all'
      }}
    >
      {apiResponse?.questionItem.question}
    </Typography>
    
    <Stack
      mt={3}
      ml={2}
      mr={2}
      spacing={2}
      sx={{zIndex: 0}}
    >

      {apiResponse?.questionItem.answerType === "TEXT" &&
        <Box>
          <Input
            placeholder="답변을 입력해 주세요"
            value={answer.answerText ? answer.answerText : ""}
            onChange={ev => setAnswer({...answer, answerText: ev.target.value})}
          />
        </Box>
      }

      {apiResponse?.questionItem.answerType === "SELECT" &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {apiResponse.questionItem.selectAnswers.map((item) => {
            return <Sheet
              variant="outlined"
              key={item}
            >
              <Checkbox
                sx={{ p: 2, borderRadius: 'md', display: 'flex' }}
                overlay
                checked={answer.answerSelect?.has(item)}
                value={item}
                label={item}
                onChange={e => { 
                  const v = e.target.value

                  if (apiResponse.questionItem.selectAllowMultiple) {
                    if (!answer.answerSelect?.has(item)) {
                      answer.answerSelect?.add(v)
                    } else {
                      answer.answerSelect?.delete(v)
                    }

                  } else {
                    if (answer.answerSelect?.has(item)) {
                      answer.answerSelect?.delete(v)
                    } else if  (answer.answerSelect.size !== 0) {
                      answer.answerSelect.clear()
                      answer.answerSelect.add(v)
                    } else {
                      answer.answerSelect?.add(v)
                    }
                  }

                  console.log(answer)
                  setAnswer({...answer})
                  e.target.checked = answer.answerSelect?.has(item)
                }}
              />
            </Sheet>
          })}
          {apiResponse.questionItem.selectAllowEtc && 
            <Sheet
              variant="outlined"
              key='기타'
            >
              <Checkbox
                sx={{ p: 2, borderRadius: 'md', display: 'flex' }}
                overlay
                checked={!!etc}
                value='기타'
                label={'기타' +(etc && (' - ' + etc) || '')}
                onChange={e => {
                  if (e.target.checked) {
                    setEtc(prompt('입력해 주세요.') || undefined)
                  } else {
                    setEtc(undefined)
                  }
                }}
              />
            </Sheet>
          }
        </Box>
      }
      <Box height={100} />

    </Stack>
  </Stack>

  <Box 
    sx={{
      backgroundColor: 'white',
      position: 'fixed',
      margin: 'auto',
      left: 0,
      right: 0,
      bottom: 0
    }}
    height={80}
  />
  <Box
    sx={{
      position: 'fixed',
      margin: 'auto',
      left: 20,
      right: 20,
      bottom: 20,
      zIndex: 1,
      backgroundColor: 'white'
    }}
  >
    <Grid
      container
      spacing={1}
    >
      <Grid xs={4}>
        <Button
          fullWidth
          size="lg"
          variant="outlined"
          onClick={() => goBack()}
          disabled={loading || apiResponse?.isFirst}
        >
          이전
        </Button>
      </Grid>


      <Grid xs={8}>
        <Button
          fullWidth
          size="lg"
          onClick={() => {
            if (apiResponse?.questionItem.answerType === "TEXT" && (answer.answerText === "" || answer.answerText === undefined)) {
              alert("답변을 입력해 주세요.")
            } else if (apiResponse?.questionItem.answerType === "SELECT" && answer.answerSelect.size === 0 && !etc) {
              alert("답변을 선택해 주세요.")
            } else {
              putAnswer()
            }
          }}
          disabled={loading}
        >
          다음
        </Button>
      </Grid>
    </Grid>
  </Box>
  </>
}